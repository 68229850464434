<template>
  <div class="page m-page">
    <p class="Title"><span style="font-family: 宋体; text-align: center;">苗仓用户协议</span></p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《苗仓用户协议》（以下简称“协议”），确保您充分理解本协议中中山苗仓供应链管理有限公司（下称“我们”或“苗仓”）为您提供的用户服务、您在注册和使用苗仓用户服务时的权利与义务，以及您在苗仓为您提供的小程序、网站、APP等应用程序或软件服务平台所包含的服务和内容（合称“苗仓平台”或“应用程序”）。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >本协议约定苗仓与用户之间关于“苗仓”软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。本协议可由苗仓随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在苗仓APP或小程序中查阅最新版协议条款。</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑"
        >在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用苗仓提供的服务，用户继续使用苗仓提供的服务将被视为接受修改后的协议。您在通过苗仓平台注册用户账号前，应确保您已年满十八（18）周岁。如果您未满十八（18）周岁，则您在注册前应当在您的父母或监护人的陪同下审阅本协议，以确保他们理解该等条款。如果您的父母或监护人不认可或不同意本协议条款的，则您应当立即停止注册并停止使用服务。</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <h1 style="line-height: 170%; font-size: 12pt">
      <span style="font-family: 微软雅黑">一、用户注册与个人信息</span>
    </h1>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >您可以通过如下苗仓平台或方式，提交注册信息成为苗仓用户：</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">苗仓官方APP</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">苗仓地被直采中心采购端小程序</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">苗仓地被直采中心苗圃端小程序</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">苗仓地被直采中心物流端小程序</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">苗仓地被直采中心跟单系统</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >苗仓不时公布、说明或认可的其他方式或平台</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >在某一上述苗仓平台注册后，您即表示同意并授权苗仓使用您提交的注册信息，同时为您在上述其他某一或多个苗仓官方平台开通苗仓用户账号，以使您立即（或在未来）可使用您的苗仓用户账号登录名及密码直接登录上述其他某一或多个苗仓官方平台，而无需再次注册。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >您在会员账号中设置或输入的用户名、头像等，以及您作为苗仓用户在参加苗仓所举办或执行的相关活动时，必须遵守法律法规及公序良俗，且不会侵害任何第三方的合法权益。否则，苗仓将有权调整、限制或取消您的用户名、昵称、头像或留言或其他会员使用功能或会员权益。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、用户在使用本服务前需要注册一个“苗仓”账号。“苗仓”账号应当使用手机号码绑定注册，请用户使用尚未与“苗仓”账号绑定的手机号码，以及未被苗仓根据本协议封禁的手机号码注册“苗仓”账号。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >苗仓可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、“苗仓”系基于苗仓开发的小程序、网站、APP应用程序和软件服务，用户注册时应当授权苗仓公开及使用其个人信息方可成功注册“苗仓”账号。故用户完成注册即表明用户同意苗仓提取、公开及使用用户的信息。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、鉴于“苗仓”账号的绑定注册方式，您同意苗仓在注册时将允许您的手机号码及手机设备识别码等信息用于注册。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >4、在用户注册及使用本服务时，苗仓需要搜集能识别用户身份的个人信息以便苗仓可以在必要时联系用户，或为用户提供更好的使用体验。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >苗仓搜集的信息包括但不限于用户的姓名、地址；苗仓同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <h1 style="line-height: 170%; font-size: 12pt">
      <span style="font-family: 微软雅黑">二、用户个人隐私信息保护</span>
    </h1>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >苗仓非常重视您的个人信息保护，根据有关法律法规要求，网络产品与服务具有收集用户信息功能的，其提供者应当向用户明示取得同意。我们特此通过单独明示的</span
      ><span style="font-family: 微软雅黑; font-weight: bold"
        >《苗仓隐私政策》</span
      ><span style="font-family: 微软雅黑"
        >明确向您告知收集、使用您的个人信息的目的、方式、范围，查询、更正信息的渠道以及拒绝提供信息的后果。建议您完整阅读《苗仓隐私政策》以帮助您更好的保护您的隐私。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、您理解并同意：苗仓将会尽其商业上的合理努力保障您在本软件及服务中的数据存储安全，但是，苗仓并不能就此提供完全保证，包括但不限于以下情形：</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（1）苗仓不对您在本软件及服务中相关数据的删除或储存失败负责；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2）苗仓有权根据实际情况自行决定您在本软件及服务中数据的最长储存期限、服务器中数据的最大存储空间等，您可根据自己的需要自行备份本软件及服务中的相关数据。双方另有约定的按相应约定执行；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">（3）</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >如果您停止使用本软件及服务或服务被终止或取消，苗仓可以从服务器上永久地删除您的数据。您的服务停止、终止或取消后，苗仓没有义务向您返还任何数据。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、如果苗仓发现或收到他人举报或投诉用户违反本协议约定的，</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >苗仓有权不经通知随时对相关内容</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span style="font-family: 微软雅黑"
        >，包括但不限于用户资料、发贴记录进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁、设备封禁、功能封禁等等的处罚，且通知用户处理结果。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、因违反用户协议被封禁的用户，可以自行与苗仓联系。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。被封禁用户可提交申诉，苗仓将对申诉进行审查，并自行合理判断决定是否变更处罚措施。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >4、用户理解并同意，苗仓有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >5、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿苗仓与合作公司、关联公司，并使之免受损害。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑; font-weight: bold"
        >三、用户发布内容规范</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、本条所述内容是指用户使用苗仓的过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用账号或本服务所产生的内容。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、用户不得利用“苗仓”账号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（1）反对宪法所确定的基本原则的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">（3）损害国家荣誉和利益的；</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（4）煽动民族仇恨、民族歧视，破坏民族团结的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（8）侮辱或者诽谤他人，侵害他人合法权益的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（9）含有法律、行政法规禁止的其他内容的信息。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、用户不得利用“苗仓”账号或本服务制作、上载、复制、发布、传播如下干扰“苗仓”正常运营，以及侵犯其他用户或第三方合法权益的内容：</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">（1）含有任何性或性暗示的；</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2）含有辱骂、恐吓、威胁内容的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（3）含有骚扰、垃圾广告、恶意信息、诱骗信息的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（4）涉及他人隐私、个人信息或资料的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（5）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（6）含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑; font-weight: bold">四、使用规则</span>
    </p>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span
          >1. 通过注册或经您授权的第三方应用账户绑定，成功注册苗仓账户，通过使用苗仓进行下单，注册成功后即成为苗仓用户，享有苗仓账户所述的权利及其它权益。</span
        >
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span
          >2. 该苗仓账户为您的专属账户，不可转赠，且每个手机号码或微信账户有且仅对应一个独立用户账户。该账号仅限您本人使用。您不得将您的苗仓账号或会员账号内的任何资产或权益转让、转移、借用、赠与他人。您的苗仓账号及相关登陆凭据是苗仓中国识别您身份的依据，请您务必妥善保管。</span
        >
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span
          >3. 您使用您的苗仓账号进行的任何操作、发出的任何指令，均将被视为由您本人做出。如因非苗仓的原因导致您的苗仓账号等信息被冒用、盗用或非法使用，由此引起的一切风险、责任、损失、费用等，均应由您自行承担。</span
        >
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span
          >4. 任何人因使用您的苗仓账户下单购买商品或者充值苗币，将视为您本人的行为，已被使用的苗币或会员权益按照规则进行扣除、抵消或减少。因此请您确保注册账户使用的手机号码或账户系您个人有权使用的手机号码或账户，您将对账户安全负全部责任。另外，每个用户都要对以其用户名进行的所有活动和事件负全责。</span
        >
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span
          >5. 使用苗仓下单时，您需要依次确认好下单的配送方式、下单的时间控制(
          目前仅支持立即下单(配送)的方式)、购买的商品等，最后进行结算并按照苗仓支持的付款方式(当前支持微信、工商银行E企付)进行付款。</span
        >
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span
          >6. 通过苗仓下单，您选择物流配送时需要填写准确的收货人、收货人手机号及收货地址信息，若您填写的信息错误或收货人拒收导致物流配送失败的，损失由您自行承担，苗仓暂不支持变更物流配送信息。</span
        >
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span
          >7. 苗仓会根据实际营运情况暂停苗仓的使用或对下单条件作出各种限制，您对此表示理解并同意。</span
        >
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span>8. 用户不得利用“苗仓”账号或本服务进行如下行为：</span>
      </li>
    </ol>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（1）
        提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（2） 强制、诱导其他用户关注、点击链接页面或分享信息的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（3） 虚构事实、隐瞒真相以误导、欺骗他人的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（4） 利用技术手段批量建立虚假账号的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（5） 利用“苗仓”账号或本服务从事任何违法犯罪活动的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（6）
        制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >（7）
        其他违反法律法规规定、侵犯其他用户合法权益、干扰“苗仓”正常运营或苗仓未明示授权的行为。</span
      >
    </p>
    <ol start="9" type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span
          >9. 用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表苗仓的观点、立场或政策，苗仓对此不承担任何责任。</span
        >
      </li>
      <li
        style="
          text-align: left;
          line-height: 150%;
          widows: 2;
          orphans: 2;
          font-family: 微软雅黑;
          font-size: 12pt;
        "
      >
        <span>&#xa0;</span
        ><span style="font-weight: bold; text-decoration: underline"
          >10. 用户须对利用“苗仓”账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与苗仓无关。</span
        ><span>如因此给苗仓或第三方造成损害的，用户应当依法予以赔偿。</span>
      </li>
    </ol>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑; font-weight: bold"
        >五、苗仓提供相关服务的免责</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、苗仓不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该外部链接指向的不由苗仓实际控制的任何网页上的内容，苗仓不承担任何责任。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、用户明确并同意其使用苗仓网络服务所存在的风险将完全由其本人承担；因其使用苗仓网络服务而产生的一切后果也由其本人承担，苗仓对此不承担任何责任。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、除苗仓注明之服务条款外，其它因不当使用苗仓APP或小程序而导致的任何意外、疏忽、合约毁坏、诽谤、版权或其他知识产权侵犯及其所造成的任何损失，苗仓概不负责，亦不承担任何法律责任。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >4、不论在何种情况下，苗仓均不对因黑客攻击、通讯线路中断、Internet连接故障，电脑,通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，骚乱，火灾，洪水，风暴，地震,爆炸，战争、重大疫情等不可抗力，政府行为，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任，但将尽力减少因此给用户造成的损失或影响。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >5、苗仓提供的服务中可能包括广告，用户同意在使用过程中显示苗仓和第三方供应商、合作伙伴提供的广告。</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span
        style="
          font-family: 微软雅黑;
          font-weight: bold;
          text-decoration: underline;
        "
        >除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，苗仓不承担任何责任。</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑; font-weight: bold">六、 赔偿</span>
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >经苗仓要求，对于因您违反本协议、使用或不当使用应用程序、或侵犯任何第三方的任何权利所引起的任何及所有索赔、诉讼、损害、费用、法律诉讼、罚款、罚金、责任和开支（包括律师费用），您同意为苗仓及其关联方（及其各自的特许经营人、跟单员、雇员、承包商、管理人员和董事）进行抗辩、向其做出赔偿并使其免受损失。对于应由您赔偿的任何事项，苗仓保留抗辩权和控制权，在此情况下，您应配合苗仓向相关第三方主张任何可行的抗辩。</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑; font-weight: bold"
        >七、 适用范围和适用法律</span
      >
    </p>
    <p style="line-height: 150%; font-size: 12pt">
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律（不包括港澳台地区）。若用户和苗仓之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交广东省中山市有管辖权的人民法院管辖。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑; font-weight: bold">八、其他</span>
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1、苗仓郑重提醒用户注意本协议中免除苗仓责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >2、本协议的更新及生效日期为：2022年7月1日。苗仓将根据具体情况的变化，不定期地修改本协议。变更后的协议将在修订生效前以苗仓官方网站的公告、手机短信、电话或客户端推送等适当方式向苗仓用户发送通知。若苗仓用户在本协议及与本协议相关的业务规则内容公告变更后继续使用苗仓用户服务的，即表示该苗仓用户已充分阅读、理解并接受修改后的协议和/或相关业务规则内容，并同意遵循修改后的协议和相关业务规则使用苗仓用户服务。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</span
      >
    </p>
    <p
      style="
        text-align: left;
        line-height: 150%;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
      "
    >
      <span style="font-family: 微软雅黑">&#xa0;</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Service',
};
</script>

<style>
body {
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 10.5pt;
}
h1,
p {
  margin: 0pt;
}
li {
  margin-top: 0pt;
  margin-bottom: 0pt;
}
h1 {
  margin-top: 0pt;
  margin-bottom: 0pt;
  text-align: justify;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 170%;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 18pt;
  font-weight: bold;
  color: #1a1a1a;
}
.Header {
  text-align: justify;
  line-height: normal;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 9pt;
}
.NormalWeb {
  margin: 0pt;
  text-align: left;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 12pt;
}
.Title {
  margin-top: 0pt;
  margin-bottom: 0pt;
  text-align: center;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 170%;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 24pt;
  font-weight: bold;
  color: #1a1a1a;
}
.page {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  word-break: break-all;
}
</style>
